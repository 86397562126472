















import { Getter, Action } from "vuex-class";
import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import { FormBase, FormSelect, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    FormBase,
    FormSelect,
    FormError,
    FormSubmit,
  },
})
export default class ModalDotAddHunch extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Action("dot/update") updateDot!: ({ payload, id }: { payload: DotPayload; id: number }) => Promise<void>;

  @Action("hunch/index") indexHunches!: (payload: SearchIndexPayload) => Promise<Hunch[]>;

  @Prop() open!: boolean;

  @Prop() dotId!: number;

  @Ref() form!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: DotPayload = {
    hunch_ids: [],
  };

  hunches: Hunch[] = [];

  private async submit() {
    this.updateDot({ payload: this.payload, id: this.dotId })
      .then(() => this.$emit("close"))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }

  private get hunchOptions(): SelectOption[] {
    return this.hunches.map((hunch: Hunch) => {
      return {
        id: hunch.id,
        label: hunch.title,
      };
    });
  }

  @Watch("open")
  handleOpen(open: boolean) {
    if (open) {
      const payload: SearchIndexPayload = {
        s: { created_at: "desc", id: "desc" },
        q: { and: { workspace_id: `${this.workspace.id}` } },
      };
      this.indexHunches(payload).then((hunches: Hunch[]) => (this.hunches = hunches));
      this.payload = {
        hunch_ids: [],
      };
    }
  }
}
